<template>
  <div
    v-if="error"
    class="v-error-color"
    v-html="error"
  />
  <div
    v-else
    :id="uid"
    class="v-captcha-container v-yandex-captcha"
    :class="{
      'v-captcha-container-visible': !appConfig.RestaurantSettingsPreRun.YandexCaptchaInvisible,
      disabled: disabled
    }"
    :inert="disabled"
  />
</template>

<script setup lang="ts">
import type { CanBeDisabled } from '@arora/common'

defineProps<CanBeDisabled>()
const restaurantStore = useRestaurantStore()

const appConfig = useAppConfig()
const { translate } = useI18nSanitized()

const { eventEmit, eventOn } = useEmitter()

const captchaScriptId: Readonly<string> = 'cje-yandex-smart-captcha-script'
const apiUrl: Readonly<string> = 'https://captcha-api.yandex.ru/captcha.js'
const captchaId = ref<string>('')
const uid = useId()
const error = ref<string | null>(null)

onMounted(() => {
  let scriptSmartCaptcha: HTMLScriptElement = document.getElementById(
    captchaScriptId
  ) as HTMLScriptElement

  if (scriptSmartCaptcha) {
    onLoadSmartCaptcha()

    return
  }

  scriptSmartCaptcha = document.createElement('script')

  scriptSmartCaptcha.id = captchaScriptId
  scriptSmartCaptcha.type = 'text/javascript'
  scriptSmartCaptcha.src = `${apiUrl}?render=onload&onload=onloadCaptchaFunction`
  scriptSmartCaptcha.defer = true
  scriptSmartCaptcha.onerror = function () {
    error.value = translate('captcha.loadingError')
  }

  window.onloadCaptchaFunction = onLoadSmartCaptcha

  document.body.appendChild(scriptSmartCaptcha)
})

function onLoadSmartCaptcha(): void {
  if (!window.smartCaptcha) {
    return
  }

  captchaId.value = window.smartCaptcha.render(uid, {
    sitekey: appConfig.RestaurantSettingsPreRun.YandexCaptchaClientKey,
    invisible: appConfig.RestaurantSettingsPreRun.YandexCaptchaInvisible
  })

  window.smartCaptcha.subscribe(captchaId.value, 'challenge-visible', () => {
    const element = document.querySelector('.SmartCaptcha-Overlay_visible')
    if (element) {
      //fix for iframe
      element.setAttribute('style', '--smart-captcha-doc-height: 100%;--smart-captcha-doc-width: 100%;')
    }

    eventEmit('v-captcha-visible')
  })

  window.smartCaptcha.subscribe(captchaId.value, 'challenge-hidden', () => {
    eventEmit('v-captcha-hidden')
  })

  eventOn('v-reset-captcha', () => {
    window.smartCaptcha.reset(captchaId.value)
  })

  window.smartCaptcha.subscribe(captchaId.value, 'success', (token: string) => {
    restaurantStore.saveYandexCaptcha(token)

    eventEmit('v-captcha-hidden')
  })
}
</script>

<style lang="scss">
.v-captcha-container {
  margin: 10px auto;
  border-radius: var(--checkbox-captcha-border-radius, 11px);
  overflow: hidden;
}

.v-captcha-container-visible {
  //according to yandex recaptcha guidelines element will be at least 100px in height
  min-height: 100px;
}

//Overlapping styles from Yandex
.v-yandex-captcha {
  min-width: 200px !important;
  max-width: 316px;
}
</style>
