<i18n>
ru:
  error: 'Невозможно проверить пользователя, номер ошибки #1cptch'
  loadingError: 'Не удалось загрузить файл каптчи (возможно из-за настроек блокировки
    контента), номер ошибки #2cptch'
ua:
  error: 'Неможливо перевірити користувача, номер помилки #1cptch'
  loadingError: 'Не вдалося завантажити файл капчі (можливо через налаштування блокування
    вмісту), номер помилки #2cptch'
us:
  error: 'Failed to validate user, the error number #1cptch'
  loadingError: 'Failed to load captcha file (possibly due to content blocking settings),
    error number #2cptch'
</i18n>

<template>
  <common-captcha-components-yandex-smart-captcha
    v-if="appConfig.RestaurantSettingsPreRun.CaptchaType === 'yandex'"
    :disabled="disabled"
  />
  <common-captcha-components-google-recaptcha
    v-else-if="appConfig.RestaurantSettingsPreRun.CaptchaType === 'google'"
    :disabled="disabled"
  />
  <span
    v-else
    class="v-error-color"
    v-html="translate('captcha.error')"
  />
</template>

<script setup lang="ts">
import type { CanBeDisabled } from '@arora/common'

defineProps<CanBeDisabled>()

const { translate } = useI18nSanitized()

const appConfig = useAppConfig()
</script>
